import { forwardRef, useState, useEffect, useRef } from 'react';
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES, MANTEINENCETOKEN, USDC_CONTRACT_ADDRESSES, DEFAULTCOIN, USDT_CONTRACT_ADDRESSES} from '../../variaveis';
import resets from './_resets.module.css';
import classes from './Body_frame.module.css';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Web3 from 'web3';
import { Buffer } from "buffer/";
import { BRLAContractAbi, USDCContractAbi, USDTContractAbi } from '../../utils/abis';
import ListaCoins from '../../Components/ListaCoinsSwap';
import ListaCoinsRecebe from '../../Components/ListaCoinsSwap';
import Checked from '../../Components/Transfer/checked.png';
import { useContext } from 'react';
import socketContext from '../../Components/Websocket/WebSocketContext';
import { useTranslation } from 'react-i18next';
function SwapMobile({  OnChangeRecebeToken, OnChangeToken}) {
  const { t, i18n} = useTranslation();
  const {socket, connectWebSocket} = useContext(socketContext);
  const [spread, setSpread] = useState([]);


useEffect(() => {
  
  const fetchData = async () => {
    try {
      const response = await fetch(`${ENDPOINT}/on-chain/usdc-usdt-spread`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        // Agora você pode usar a variável 'data' para acessar os dados da resposta
        console.log(data);
        setSpread(data);
      } else if (response.status === 401) {
        // Redirecionar para a página /login se o status for 401
        navigate('/login');
      } else {
        throw new Error('Erro na requisição');
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []);

  
  
  const [users, setUsers] = useState([]);
  const [fees, setFees] = useState([]);
  const [balance, setBalance] = useState([]);
  const [balanceUsd, setBalanceUsd] = useState([]);
  const [balanceUsdt, setBalanceUsdt] = useState([]);
  const [pixKeyType, setPixKeyType] = useState(users?.kyc?.documentType === 'CPF' ? 'CPF' : 'CNPJ');
  const [pixKey, setPixKey] = useState('');
  const [transferType, settransferType] = useState('PIX');
  const [pixKeyValid, setPixKeyValid] = useState(false);
  const [erroreq, seterroreq] = useState(false);
  const [currencyType, setcurrencyType] = useState('');
  const [CpfCnpj, setCpfCnpj] = useState('');
  const [Token, setToken] = useState(null);
  const [SocketMessage, setSocketMessage] = useState([]);
  const [ValidCpfCnpj, setValidCpfCnpj] = useState(false);
  const [marginLeftValue, setMarginLeftValue] = useState('100px');
  const [marginLeftValueP, setMarginLeftValueP] = useState('70px');
  const [codeoneActive, SetcodeoneActive] = useState(false);
  const [codetwoActive, SetcodetwoActive] = useState(false);
  const [codetreeActive, SetcodetreeActive] = useState(false);
  const [codefourActive, SetcodefourActive] = useState(false);
  const [codefiveActive, SetcodefiveActive] = useState(false);
  const [codesixActive, SetcodesixActive] = useState(false);
  useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedFees = localStorage.getItem('feesData');
    if (loadedFees) {
      setFees(JSON.parse(loadedFees));
    }



   if (!socket || socket.readyState !== socket.OPEN) {
   connectWebSocket();
   }
 }, []);
  const isCPFValid = (strCPF) => {
    let Soma = 0;
    let Resto;
  
    if (strCPF === "00000000000") return false;
  
    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }
  
    Resto = (Soma * 10) % 11;
  
    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;
  
    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    }
  
    Resto = (Soma * 10) % 11;
  
    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  
    return true;
  };

  function validaCNPJ (cnpj) {
    var b = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]
    var c = String(cnpj).replace(/[^\d]/g, '')
    
    if(c.length !== 14)
        return false

    if(/0{14}/.test(c))
        return false

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if(c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    return true
}


  const handlePixKeyChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setPixKeyValid(false)
    const inputValue = event.target.value;
    let formattedValue = inputValue;
  
    

    setPixKey(inputValue);
  };

  const handleCpfCnpjChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setValidCpfCnpj(false);
    const inputValue = event.target.value;
    let formattedValue = inputValue;
  
    

    setCpfCnpj(inputValue);
  }; 

  function isValidEmailFormat(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  
  const handlePixKeyFormat = () => {
    let formattedValue = pixKey;
  
    // Check for CPF format (11 digits)
    if (isCPFValid(pixKey) && /^\d{11}$/.test(pixKey)) {
      formattedValue = `${pixKey.substring(0, 3)}.${pixKey.substring(3, 6)}.${pixKey.substring(6, 9)}-${pixKey.substring(9)}`;
      
    }
  
     // Check for CNPJ format (14 digits)
  if (validaCNPJ(pixKey) && /^\d{14}$/.test(pixKey)) {
    formattedValue = `${pixKey.substring(0, 2)}.${pixKey.substring(2, 5)}.${pixKey.substring(5, 8)}/${pixKey.substring(8, 12)}-${pixKey.substring(12)}`;
    
  }

  // Check for Cell Phone format (11 or 12 digits)
  if (/^\d{11,12}$/.test(pixKey) && !isCPFValid(pixKey) && !validaCNPJ(pixKey)) {
    formattedValue = `+55 (${pixKey.substring(0, 2)}) ${pixKey.substring(2, 7)}-${pixKey.substring(7)}`;
    
  }

  if (pixKey.replace(' ','').replace(/-/g, '').length === 32) {
   setPixKeyValid(true);
   setPixKeyType('Chave-aleatoria');
   GetPixInfo(pixKey.replace(' ',''));
   console.log("chave aleatoria")
  }

  if (pixKey.replace('+55','',).replace('(','').replace(')').replace(' ').replace('-','').length === 11) {
    setPixKeyValid(true)
    setPixKeyType('Celular');
    GetPixInfo(pixKey.replace('+55','',).replace('(','').replace(')').replace(' ').replace('-',''))
   }
  
  if (isValidEmailFormat(pixKey.replace(' ',''))) {
    setPixKeyValid(true)
    setPixKeyType('Email');
    GetPixInfo(pixKey.replace(' ',''))
  }


  if (isCPFValid(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''))) {
    setPixKeyValid(true);
    setPixKeyType('CPF');
    setValidCpfCnpj(true);
    GetPixInfo(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));

  }

  if (validaCNPJ(pixKey.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))) {
    setPixKeyValid(true);
    setPixKeyType('CNPJ');
    setValidCpfCnpj(true);
    GetPixInfo(pixKey.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))
  }

    setPixKey(formattedValue);
  };



  const handleCpfCnpjFormat = () => {
    let formattedValue = CpfCnpj;
  
    // Check for CPF format (11 digits)
    if (isCPFValid(CpfCnpj) && /^\d{11}$/.test(CpfCnpj)) {
      formattedValue = `${CpfCnpj.substring(0, 3)}.${CpfCnpj.substring(3, 6)}.${CpfCnpj.substring(6, 9)}-${CpfCnpj.substring(9)}`;
      
    }
  
     // Check for CNPJ format (14 digits)
  if (validaCNPJ(CpfCnpj) && /^\d{14}$/.test(CpfCnpj)) {
    formattedValue = `${CpfCnpj.substring(0, 2)}.${CpfCnpj.substring(2, 5)}.${CpfCnpj.substring(5, 8)}/${CpfCnpj.substring(8, 12)}-${CpfCnpj.substring(12)}`;
    
  }





  if (isCPFValid(CpfCnpj.replace('.','').replace('-','').replace('.','').replace(' ',''))) {
    setValidCpfCnpj(true);
    setCpfCnpj(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));

  }

  if (validaCNPJ(CpfCnpj.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))) {
    setValidCpfCnpj(true);
    setCpfCnpj(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));
  }

    setCpfCnpj(formattedValue);
  };

  const handleWalletChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setWalletValue(event.target.value);
    
  };


  useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      const parsedUsers = JSON.parse(loadedUsers);
    setUsers(parsedUsers);
    setPixKeyType(parsedUsers.kyc.documentType === 'CPF' ? 'CPF' : 'CNPJ');
    }


    
    const fetchBalance = async () => {
    
   
      // Cria uma nova instância do web3 com o Infura
      const web3 = new Web3(new Web3.providers.HttpProvider("https://polygon-rpc.com/"));

      // Mapeia cada carteira para um objeto que inclui o saldo BRLA
      
          const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[137];
          const USDC_CONTRACT_ADDRESS = USDC_CONTRACT_ADDRESSES[137];
          const USDT_CONTRACT_ADDRESS = USDT_CONTRACT_ADDRESSES[137];
          const contract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
          const contractUsd = new web3.eth.Contract(USDCContractAbi, USDC_CONTRACT_ADDRESS);
          const contractUsdt = new web3.eth.Contract(USDTContractAbi, USDT_CONTRACT_ADDRESS);
          const balance = await contract.methods.balanceOf(users?.wallets?.evm).call();
          const balanceUsd = await contractUsd.methods.balanceOf(users?.wallets?.evm).call();
          const balanceUsdt = await contractUsdt.methods.balanceOf(users?.wallets?.evm).call();
          setBalance(balance)
          setBalanceUsdt(balanceUsdt);
          setBalanceUsd(balanceUsd);

      
      

      
  };

  if (users?.wallets?.evm) {
    fetchBalance();
  }



  }, [users?.wallets?.evm]);
  
  const handlePixKeyTypeChange = (type) => {
    setPixKeyType(type);
    setPixKey('')
  };

  const handleTransferTypeChange = (type) => {
    settransferType(type);
    setPixKey('');
  };

  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [priceusd, setPriceusd] = useState('');
  const navigate = useNavigate();

  const [message, setMessage] = useState({ text: '', color: '' });

  useEffect(() => {
    // Detectar sistema operacional
    const os = window.navigator.platform;
    const userAgent = window.navigator.userAgent;
    const linuxPattern = /Linux/;
    const windowsPattern = /Win/;
    const firefoxPattern = /Firefox/;
  
    // Se o sistema operacional for Linux ou o navegador for Firefox, defina marginLeft para '30px'
    if ( firefoxPattern.test(userAgent)) {
      setMarginLeftValue('20px');
      setMarginLeftValueP('-10px');
    }

    else if (linuxPattern.test(os)) {

      setMarginLeftValue('60px');
      setMarginLeftValueP('30px');
      
    }
  
    // Se o sistema operacional for Windows, defina marginLeft para '100px'
    else {
      setMarginLeftValue('100px');
      setMarginLeftValueP('70px');
    }
  }, []);
  

 

  const {
    register,
    handleSubmit,
    setFocus,
    control, // Adicione esta linha
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const [mintValue, setMintValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [ishighValue, setIshighvalue] = useState(false);
  const [recebeValue, setRecebeValue] = useState('');
  const [walletValue, setWalletValue] = useState('');
  const [isInvalidrecebe, setIsInvalidRecebe] = useState(false);
  const handleMintInputChange = (e) => {
    let value = e.target.value;
    setMessage('');
  setRecebeValue('');
  setIshighvalue(false);
    // Remove qualquer caractere que não seja número, ponto ou vírgula
    value = value.replace(/[^\d.,]/g, '');
  
    // Adiciona o "R$" apenas para exibição no input
    const displayValue = `${value}`;
  
    // Verifica se o valor é uma string vazia
    if (value === '') {
      setMintValue(displayValue);
     
      setIsInvalid(false);
      return;
    }

    if ((parseFloat(value)>50000 && selectedToken.abbreviation==='USD' && selectedTokenRecebe.abbreviation==='USDT' ) || (parseFloat(value)>50000 && selectedToken.abbreviation==='USDT' && selectedTokenRecebe.abbreviation==='USDC' ) || (parseFloat(value)>50000 && selectedToken.abbreviation==='USDT' && selectedTokenRecebe.abbreviation==='USD' )) {
      setIshighvalue(true)
    }
  
    // Remove o "R$" para realizar a validação numérica
    const numericValue = parseFloat(value.replace('R$', '').replace(',', '.'));
  
    // Verifica se o valor é um número com até duas casas decimais
    if (/^\d*\.?\d{0,2}$/.test(numericValue)) {
      setMintValue(displayValue);
      if (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='BRL') {
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balance / Math.pow(10, 18)));
      if (displayValue<fees?.pixOutFee/100) {
        setRecebeValue(0);
      }
      else {
        setRecebeValue(displayValue-fees?.pixOutFee/100);
      }}
      else if (selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='BRL') {
        setRecebeValue(SocketMessage?.data?.amountUsd/100);
        
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:selectedTokenRecebe.abbreviation,
            usdToBrla: false,
            fixOutput: false,
            operation: "swap"
                        
                    }
      });
      }

      else if (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USD') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: false,
            operation: "swap"
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation==='USD' && selectedToken.abbreviation==='BRL') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:DEFAULTCOIN,
            usdToBrla: false,
            fixOutput: false,
            operation: "swap"
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USDT') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:'USDT',
            usdToBrla: true,
            fixOutput: false,
            operation: "swap"
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='BRL') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:'USDT',
            usdToBrla: false,
            fixOutput: false,
            operation: "swap"
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USD') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: false,
            operation: "swap"
                        
                    }
      });
        
      }


      else if (selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USDT') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:"Polygon",
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: false,
            operation: "swap"
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='USD') {
        setRecebeValue(parseFloat(displayValue));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsd / Math.pow(10, 6)));
        
      }

      
      else if (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD') {
        setRecebeValue((parseFloat(displayValue)*parseFloat(spread.spread)).toFixed(2));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsd / Math.pow(10, 6)));
        
      }

      else if ((selectedTokenRecebe.abbreviation==='USDC' || selectedTokenRecebe.abbreviation==='USD') && selectedToken.abbreviation==='USDT') {
        setRecebeValue((parseFloat(displayValue)*parseFloat(spread.spread)).toFixed(2));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsdt / Math.pow(10, 6)));
        
      }

      else if (selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL') {
        setRecebeValue(parseFloat(displayValue));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balance / Math.pow(10, 18)));
        
      }
     
      
    }
  };
  




  const handleRecebeInputChange = (e) => {
    let value = e.target.value;
    setMessage('');
  setMintValue('');
  setIshighvalue(false);
    // Remove qualquer caractere que não seja número, ponto ou vírgula
    value = value.replace(/[^\d.,]/g, '');
  
    // Adiciona o "R$" apenas para exibição no input
    const displayValue = `${value}`;
  
    // Verifica se o valor é uma string vazia
    if (value === '') {
      setRecebeValue(displayValue);
      setIsInvalidRecebe(false);
      return;
    }

    if ( (parseFloat(value)>50000 && selectedToken.abbreviation==='USD' && selectedTokenRecebe.abbreviation==='USDT') || (parseFloat(value)>50000 && selectedToken.abbreviation==='USDT' && selectedTokenRecebe.abbreviation==='USDC') || (parseFloat(value)>50000 && selectedToken.abbreviation==='USDT' && selectedTokenRecebe.abbreviation==='USD') ) {
      setIshighvalue(true)
    }
  
    // Remove o "R$" para realizar a validação numérica
    const numericValue = parseFloat(value.replace('R$', '').replace(',', '.'));
  
    // Verifica se o valor é um número com até duas casas decimais
    if (/^\d*\.?\d{0,2}$/.test(numericValue)) {
      if ((selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='BRL')) {
        setMintValue(parseFloat(displayValue) + fees?.pixOutFee/100);
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue+fees?.pixOutFee/100) > (balance / Math.pow(10, 18)));
      }
      else if ((selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USD')) {
        setMintValue((parseFloat(displayValue)/(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: true,
            operation: "swap"
                        
                    }
      });

      }
      else if ((selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USD')) {
        setMintValue((parseFloat(displayValue)/(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: true,
            operation: "swap"
                        
                    }
      });

      }

      else if ((selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USDT')) {
        setMintValue((parseFloat(displayValue)/(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:'USDT',
            usdToBrla: true,
            fixOutput: true,
            operation: "swap"
                        
                    }
      });

      }

      else if ((selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='BRL')) {
        setMintValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
console.log("A mensagem do socket é", SocketMessage);
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:selectedTokenRecebe.abbreviation,
            usdToBrla: false,
            fixOutput: true,
            operation: "swap"
                        
                    }
      });
      }

      else if ((selectedTokenRecebe.abbreviation==='USD' && selectedToken.abbreviation==='BRL')) {
        setMintValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
console.log("A mensagem do socket é", SocketMessage);
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:'USDC',
            usdToBrla: false,
            fixOutput: true,
            operation: "swap"
                        
                    }
      });
      }

      else if ((selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='BRL')) {
        setMintValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
console.log("A mensagem do socket é", SocketMessage);
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:selectedTokenRecebe.abbreviation,
            usdToBrla: false,
            fixOutput: true,
            operation: "swap"
                        
                    }
      });
      }


      else if ((selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USDT')) {
        setMintValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
console.log("A mensagem do socket é", SocketMessage);
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:"Polygon",
            coin:selectedTokenRecebe.abbreviation,
            usdToBrla: true,
            fixOutput: true,
            operation: "swap"
                        
                    }
      });
      }

      

      else if ((selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='USD')) {
        setMintValue(parseFloat(displayValue));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsd / Math.pow(10, 6)));
      }

      else if ((selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL')) {
        setMintValue(parseFloat(displayValue));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balance / Math.pow(10, 18)));
      }

      else if (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD') {
        setMintValue((parseFloat(displayValue)/parseFloat(spread.spread)).toFixed(2));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsd / Math.pow(10, 6)));
        
      }

      else if ((selectedTokenRecebe.abbreviation==='USDC' || selectedTokenRecebe.abbreviation==='USD' ) && selectedToken.abbreviation==='USDT') {
        setMintValue((parseFloat(displayValue)/parseFloat(spread.spread)).toFixed(2));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsd / Math.pow(10, 6)));
        
      }
   
        
      }
    
     
    
      setRecebeValue(displayValue);
   
    
  };


  useEffect(() => {

    setCompleted(false);
    setMessage('');
    setRecebeValue('');
    setMintValue('');
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:100000,
        chain:"Polygon",
        coin:DEFAULTCOIN,
        usdToBrla: false,
        fixOutput: false,
        operation: "swap"
                    
                }
  });

  }, []);

  useEffect(() => {
    setMessage('');
    if (recebeValue === '') {
      setMintValue('');
      setIsInvalid(false)
    } 

    if (isNaN(mintValue)) {
      setIsInvalid(true)
    }
    

    if (selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='BRL') {
      setIsInvalid(true)
      if (!(isNaN(mintValue) || isNaN(recebeValue))) { 
      setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='')};
    
  
    } 

    if (selectedTokenRecebe?.abbreviation==='USDT' && selectedToken?.abbreviation==='BRL') {
      setIsInvalid(true)
      if (!(isNaN(mintValue) || isNaN(recebeValue))) { 
      setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='')};
    
  
    } 
    
    if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }

    if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USDT') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsdt / Math.pow(10, 6)) || mintValue==='')};
    }

    if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }

    if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USDT') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsdt / Math.pow(10, 6)) || mintValue==='')};
    }

   





  }, [recebeValue]);

  
  
  useEffect(() => {
    setMessage('');
    if (mintValue === '') {
      setRecebeValue('');
      setIsInvalid(false)
    } 
    if (isNaN(mintValue) || mintValue ==='') {
      setIsInvalid(true)
    }

    if (selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='BRL') {
      setIsInvalid(true)
      if (!(isNaN(mintValue) || isNaN(recebeValue))) { 
      setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='')};
    
  
    } 

    
    if (selectedTokenRecebe?.abbreviation==='USDT' && selectedToken?.abbreviation==='BRL') {
      setIsInvalid(true)
      if (!(isNaN(mintValue) || isNaN(recebeValue))) { 
      setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='')};
    
  
    } 
    
    if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }

    if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USDT') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsdt / Math.pow(10, 6)) || mintValue==='')};
    }

    if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }

    
    if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USDT') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsdt / Math.pow(10, 6)) || mintValue==='')};
    }

    if (selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='USD') {

      setRecebeValue(mintValue)

    }

    
    
    
  }, [mintValue]);
  

  useEffect(() => {
    if(SocketMessage?.data?.amountUsd) {
        setPriceusd(SocketMessage.data.amountBrl/SocketMessage.data.amountUsd)
        if (selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='BRL') {
          setMintValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='');
        }

        if (selectedTokenRecebe?.abbreviation==='USD' && selectedToken?.abbreviation==='BRL') {
          setMintValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='');
        }

        if (selectedTokenRecebe?.abbreviation==='USDT' && selectedToken?.abbreviation==='BRL') {
          setMintValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='');
        }

        if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') {
          setMintValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='');
        }
        
        if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USDT') {
          setMintValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setIsInvalid((mintValue) > (balanceUsdt / Math.pow(10, 6)) || mintValue==='');
        }

        if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USDT') {
          setMintValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setIsInvalid((mintValue) > (balanceUsdt / Math.pow(10, 6)) || mintValue==='');
        }

        if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') {
          setMintValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='');
        }

        if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') {
          setMintValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='');
        }
        
    }
}, [SocketMessage]);


  const isFormValid = () => {
    return mintValue !== '' && pixKey !== '' && !isInvalid;
  };
 

  const ClickUsa = (e) => {
    e.stopPropagation(); // Impede a propagação do evento de clique
    setcurrencyType('USA');
    setPixKey('');
  }

  const ClickBrazil = (e) => {
    e.stopPropagation(); // Impede a propagação do evento de clique
    setcurrencyType('Brazil');
    setPixKey('');
  }



  const [showListaCoins, setShowListaCoins] = useState(false);
  const [showListaCoinsRecebe, setShowListaCoinsRecebe] = useState(false);
  const ListaCoinsRef = useRef();
  const ListaCoinsRecebeRef = useRef();

  const buttonRefListaCoinst = useRef(null);
  const buttonRefListaCoinsRecebet = useRef(null);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaCoins && ListaCoinsRef.current && !ListaCoinsRef.current.contains(event.target) && !buttonRefListaCoinst.current.contains(event.target)) {
      setShowListaCoins(false);
    
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaCoins]);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaCoinsRecebe && ListaCoinsRecebeRef.current && !ListaCoinsRecebeRef.current.contains(event.target) && !buttonRefListaCoinsRecebet.current.contains(event.target)) {
      setShowListaCoinsRecebe(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaCoinsRecebe]);


const [selectedToken, setSelectedToken] = useState({
  flag: 'currency-flag-brl',
  abbreviation: 'BRL'
});


const [selectedTokenRecebe, setSelectedTokenRecebe] = useState({
  flag: 'currency-flag-usdc',
  abbreviation: 'USD'
});

const handleTokenSelect = (token) => {
  setMintValue('');
  setRecebeValue('');
  if (token.abbreviation !== selectedTokenRecebe.abbreviation) {
    setSelectedToken(token);
    setShowListaCoins(false); // Close the dropdown after selection
  }
  else {
    setSelectedToken(token);
    if (token.abbreviation==='BRL') {

      setSelectedTokenRecebe({
        flag: 'currency-flag-usdc',
        abbreviation: 'USD'
      });
    }
    else if (token.abbreviation==='USD') {
      setSelectedTokenRecebe({
        flag: 'currency-flag-brl',
        abbreviation: 'BRL'
      });
    }
    else if (token.abbreviation==='USDT') {
      setSelectedTokenRecebe({
        flag: 'currency-flag-usdc',
        abbreviation: 'USD'
      });
    }
    setShowListaCoins(false); 
  }


  if (token.abbreviation === 'USD') {
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:100000,
        chain:"Polygon",
        coin:DEFAULTCOIN,
        usdToBrla: true,
        fixOutput: false,
        operation: "swap"
                    
                }
  });
  }

  else if (token.abbreviation === 'USDT') {

    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:100000,
        chain:"Polygon",
        coin:'USDT',
        usdToBrla: true,
        fixOutput: false,
        operation: "swap",
        
                    
                }
  });

  }
  else {
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:100000,
        chain:"Polygon",
        coin:DEFAULTCOIN,
        usdToBrla: false,
        fixOutput: false,
        operation: "swap"
                    
                }
  });
  }
};

const handleTokenRecebeSelect = (token) => {
  if (token.abbreviation !== selectedToken.abbreviation) {
    setSelectedTokenRecebe(token);
    setShowListaCoinsRecebe(false); // Close the dropdown after selection
  }
  else {
    setSelectedTokenRecebe(token);
    if (token.abbreviation==='BRL') {

      setSelectedToken({
        flag: 'currency-flag-usdc',
        abbreviation: 'USD'
      });
    }
    else if (token.abbreviation==='USD') {
      setSelectedToken({
        flag: 'currency-flag-brl',
        abbreviation: 'BRL'
      });
    }
    else if (token.abbreviation==='USDT') {
      setSelectedToken({
        flag: 'currency-flag-usdc',
        abbreviation: 'USD'
      });
    }
    if (token.abbreviation === 'USDT' && selectedToken.abbreviation === 'BRL') {
      sendMessage({
        messageId: "qualquer",
        operation: "Quote",
        data: {
          amount: 100000,
          chain: "Polygon",
          coin: "USDT",
          usdToBrla: false,
          fixOutput: false,
          operation: "swap"
        }
      });
    } else if (token.abbreviation === 'USDC' && selectedToken.abbreviation === 'BRL') {
      sendMessage({
        messageId: "qualquer",
        operation: "Quote",
        data: {
          amount: 100000,
          chain: "Polygon",
          coin: "USDC",
          usdToBrla: false,
          fixOutput: false,
          operation: "swap"
        }
      });
    
  }}
  setShowListaCoinsRecebe(false); // Close the dropdown after selection
};

const currencyFlagClass = `currency-flag ${selectedToken.flag}`;
const currencyFlagRecebeClass = `currency-flag ${selectedTokenRecebe.flag}`;
const currencyFlagPIXClass = `currency-flag currency-flag-pix`;
const currencyFlagPolygonClass = `currency-flag currency-flag-polygon`;
const sendMessage = (messageObject) => {
  if (socket && socket.readyState === socket.OPEN) {
    console.log("Socket está recebendo :",messageObject)
      socket.send(JSON.stringify(messageObject));
  } else {
      console.error("socket is not open.");
  }
}

useEffect(() => {
  if (socket) {
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("retorno socket", data);
      if (data.success && data?.data?.quoteId) {
        setCompleted(true);
        setIsLoading(false);
      }
      else {
        setIsLoading(false);
        
      }
      if (!data?.success && data?.error && ((selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='BRL') || (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') || (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USDT') || (selectedTokenRecebe?.abbreviation==='USDT' && selectedToken?.abbreviation==='BRL') || (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USDT') || (selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='BRL') || (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') || (selectedTokenRecebe?.abbreviation==='USD' && selectedToken?.abbreviation==='BRL') ) ) {
        setMessage({text: data.error, color:'red'});
        setIsLoading(false);
    
      }
      setSocketMessage(data);
     
    };
  }

  return () => {
    if (socket) {
      socket.onmessage = null;
    }
  };
}, [socket]);



const onSubmit = async (data) => {
  setIsLoading(true); // Definir como loading quando a requisição começar
 
if (((selectedTokenRecebe.abbreviation===DEFAULTCOIN || selectedTokenRecebe.abbreviation==='USDT' || selectedTokenRecebe.abbreviation==='USD' ) && selectedToken.abbreviation==='BRL' ))   {
  setIsLoading(true); // Definir como loading quando a requisição começar
 
  

  sendMessage({
    messageId: "qualquer",
    operation: "PlaceSwapOrder",
    data: {
      quoteId:SocketMessage?.data?.quoteId,
      notifyEmail:true,
      otp: data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6
                  
              }
});





}

if ( (selectedTokenRecebe.abbreviation==='BRL' && (selectedToken.abbreviation==='USD' || selectedToken.abbreviation==='USDT') )) {
   
  setIsLoading(true)

  sendMessage({
    messageId: "qualquer",
    operation: "PlaceSwapOrder",
    data: {
      quoteId:SocketMessage?.data?.quoteId,
      notifyEmail:true,
      otp: data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6
                  
              }
});




}





if ((selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='USD') || (selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL') || (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD') || (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USDT') || (selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USDT') || (selectedTokenRecebe.abbreviation==='USD' && selectedToken.abbreviation==='USDT') ) {

  setIsLoading(true); // Definir como loading quando a requisição começar
  setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
  const response = await fetch(`${ENDPOINT}/on-chain/transfer?otp=${data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "MAINTENANCE-TOKEN":MANTEINENCETOKEN,
    },
    credentials:'include',
    body: JSON.stringify({
      chain: "Polygon",
      inputCoin:  selectedToken.abbreviation==='USD' ? 'USDC' : selectedToken.abbreviation,
      outputCoin:selectedTokenRecebe.abbreviation==='USD' ? 'USDC' : selectedTokenRecebe.abbreviation,
      value: parseFloat((parseFloat(recebeValue)*100).toFixed(0)),
      to: users?.wallets?.evm
    }),
  });
  setIsLoading(false); // Definir como não loading quando a requisição terminar

  if (response.status === 200 || response.status === 201) {
    
    setCompleted(true)
    // Não reabilite o botão
  } else {
    let responseData = {};
    if (response.headers.get('content-type').includes('application/json')) {
      responseData = await response.json();
    }
    // Trate erros aqui, caso necessário
   setMessage({text: responseData.error, color: 'red'})

}

if (response.status === 401) {
  setMessage({text: 'Erro: Refaça o login', color: 'red'});
  navigate('/login');

  // Reabilite o botão
  setIsLoading(false);
}

}




}

const handleCoinSelected = (selectedCoin) => {
  if (selectedCoin?.abbreviation === 'USD') {
  }
  // Aqui, você pode adicionar outras condições para diferentes moedas, se necessário.
};

const getClass = () => {
 
   return 'swap'

}
const [pixInfo, setPixInfo] = useState(null);

async function GetPixInfo(pixKeyinput) {
  const response = await fetch(`${ENDPOINT}/pay-out/pix-info?pixKey=${pixKeyinput}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "MAINTENANCE-TOKEN":MANTEINENCETOKEN,
    },
    credentials:'include',
  });
  setIsLoading(false); // Definir como não loading quando a requisição terminar

  if (response.status === 200 || response.status === 201) {
    const responseData = await response.json();
    setPixInfo(responseData);

    
    // Não reabilite o botão
  } else {
    let responseData = {};
    if (response.headers.get('content-type').includes('application/json')) {
      responseData = await response.json();
    }
    // Trate erros aqui, caso necessário
   setMessage({text: responseData.error, color: 'red'})

}
}

function toTitleCase(str) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

function handleMax() {
  if (selectedToken.abbreviation === 'BRL' && selectedTokenRecebe.abbreviation === DEFAULTCOIN) {
    let value = balance / Math.pow(10, 18);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:"Polygon",
        coin:selectedTokenRecebe.abbreviation,
        usdToBrla: false,
        fixOutput: false,
        operation: "swap"
                    
                }
  });
  } 

  else if (selectedToken.abbreviation === 'BRL' && selectedTokenRecebe.abbreviation === 'USD') {
    let value = balance / Math.pow(10, 18);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:"Polygon",
        coin:'USDC',
        usdToBrla: false,
        fixOutput: false,
        operation: "swap"
                    
                }
  });
  } 


  else if ((selectedToken.abbreviation === 'USD' && selectedTokenRecebe.abbreviation === 'BRL')) {
    let value = balanceUsd / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:"Polygon",
        coin:'USDC',
        usdToBrla: true,
        fixOutput: false,
        operation: "swap"
                    
                }
  });
  }


  else if ((selectedToken.abbreviation === 'USDT' && selectedTokenRecebe.abbreviation === 'BRL')) {
    let value = balanceUsdt / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:"Polygon",
        coin:'USDT',
        usdToBrla: true,
        fixOutput: false,
        operation: "swap"
                    
                }
  });
  }

  else if ((selectedToken.abbreviation === 'BRL' && selectedTokenRecebe.abbreviation === 'USDT')) {
    let value = balance / Math.pow(10, 18);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:"Polygon",
        coin:'USDT',
        usdToBrla: false,
        fixOutput: false,
        operation: "swap"
                    
                }
  });
  }

  else if ((selectedToken.abbreviation === 'USD' && selectedTokenRecebe.abbreviation === 'BRLA')) {
    let value = balanceUsd / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:"Polygon",
        coin:'USDC',
        usdToBrla: true,
        fixOutput: false,
        operation: "swap"
                    
                }
  });
  }

  
  else if ((selectedToken.abbreviation === 'USDT' && selectedTokenRecebe.abbreviation === 'BRLA')) {
    let value = balanceUsdt / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:"Polygon",
        coin:'USDT',
        usdToBrla: true,
        fixOutput: false,
        operation: "swap"
                    
                }
  });
  }

  else if ((selectedToken.abbreviation === 'USD' && selectedTokenRecebe.abbreviation === DEFAULTCOIN)) {
    let value = balanceUsd / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    setRecebeValue(finalValue)
    

  }


  else if ((selectedToken.abbreviation === 'USDT' && selectedTokenRecebe.abbreviation === 'USD')) {
    let value = balanceUsdt / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    const result = finalValue * spread.spread;
    const roundedResult = Math.floor(result * 100) / 100; // Arredonde para baixo na segunda casa decimal
    setRecebeValue(roundedResult);
    
    

  }

  else if ((selectedToken.abbreviation === 'USDT' && selectedTokenRecebe.abbreviation === DEFAULTCOIN)) {
    let value = balanceUsdt / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    setRecebeValue(finalValue)
    

  }

  else if ((selectedToken.abbreviation === 'USDT' && selectedTokenRecebe.abbreviation === 'USDT')) {
    let value = balanceUsdt / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    setRecebeValue(finalValue)
    

  }


  else if ((selectedToken.abbreviation === 'USD' && selectedTokenRecebe.abbreviation === 'USDT')) {
    let value = balanceUsd / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    setRecebeValue(finalValue)
    

  }


  else if ((selectedToken.abbreviation === 'BRL' && selectedTokenRecebe.abbreviation === 'BRL')) {
    let value = balance / Math.pow(10, 18);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    setRecebeValue(finalValue-fees?.pixOutFee/100)
    

  }

  else if ((selectedToken.abbreviation === 'BRL' && selectedTokenRecebe.abbreviation === 'BRLA')) {
    let value = balance / Math.pow(10, 18);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    setRecebeValue(finalValue)
    

  }

  


}

    return (
<>
        {!completed ? (<form style={{zIndex:100000}} onSubmit={handleSubmit(onSubmit)}>
        <div style={{marginTop:'-50px'}} className={`${resets.storybrainResets} ${classes.root}`}>
           
              <div className={classes.section}>
              {showListaCoins && <ListaCoins ref={ListaCoinsRef} onTokenSelectSwap={handleTokenSelect} left='0px' top='100px'   />}
                <div className={classes.divH2dC43aaa00}>
     
                  <div className={classes.frame9}>
               
                    <div className={classes.labelVoceEnviaExatamente}>Você envia exatamente</div>
                  
                    <button type='button' onClick={handleMax} className={`${classes.buttonMax} limpar-botao limpar-botao-2`}>Max</button>
                  </div>
                  
                  <div className={classes.divTwMoneyInput}>
                    <div className={classes.pseudo}>
                    <input
                    onChange={handleMintInputChange}
                    value={
                      isNaN(mintValue) ? "Carregando..." : mintValue
                    }
                    placeholder='1000.00' className={`limpar-input ${classes._10000}`} />
        
                    </div>
                    <button 
                  
                    type='button' className={`${classes.divDFlex} limpar-botao`}>
                    <div className={classes.iCurrencyFlag2}>
  <div   ref={buttonRefListaCoinst}
                    onClick={() => {
                        setMintValue('');
                        setRecebeValue('');
                        setShowListaCoins(!showListaCoins);
                      }} className={classes.brlSvg2}>
    <>
      {selectedToken.abbreviation === 'BRL' ? (
        <svg preserveAspectRatio='none' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g clipPath='url(#clip0_62_81)'>
            <mask
              id='mask0_62_81'
              style={{
                maskType: 'luminance',
              }}
              maskUnits='userSpaceOnUse'
              x={0}
              y={0}
              width={24}
              height={24}
            >
              <path d='M24 0H0V24H24V0Z' fill='white' />
            </mask>
            <g mask='url(#mask0_62_81)'>
              <path
                d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37259 18.6274 0 12 0C5.37259 0 0 5.37259 0 12C0 18.6274 5.37259 24 12 24Z'
                fill='#005B13'
              />
              <path d='M1.5 12.0001L12 5.00006L22.5 12.0001L12 19.0001L1.5 12.0001Z' fill='#FFD400' />
              <path
                d='M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51473 14.4853 7.5 12 7.5C9.51473 7.5 7.5 9.51473 7.5 12C7.5 14.4853 9.51473 16.5 12 16.5Z'
                fill='#231D9A'
              />
              <path
                d='M9.49957 10.4999C12.1496 10.4999 14.5496 11.5749 16.2996 13.2749C16.3496 13.0999 16.3996 12.8999 16.4246 12.6999C14.5996 11.0249 12.1746 9.99994 9.49957 9.99994C8.9496 9.99994 8.4246 10.0499 7.92458 10.1249C7.84958 10.2999 7.77457 10.4749 7.69957 10.6749C8.2746 10.5499 8.8746 10.4999 9.49957 10.4999Z'
                fill='white'
              />
            </g>
          </g>
          <defs>
            <clipPath id='clip0_62_81'>
              <rect width={24} height={24} fill='white' />
            </clipPath>
          </defs>
        </svg>
      ) : selectedToken.abbreviation === 'USD' ? (
        <svg data-name="86977684-12db-4850-8f30-233a7c267d11" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000">
        <path d="M1000 2000c554.17 0 1000-445.83 1000-1000S1554.17 0 1000 0 0 445.83 0 1000s445.83 1000 1000 1000z" fill="#2775ca"/>
        <path d="M1275 1158.33c0-145.83-87.5-195.83-262.5-216.66-125-16.67-150-50-150-108.34s41.67-95.83 125-95.83c75 0 116.67 25 137.5 87.5 4.17 12.5 16.67 20.83 29.17 20.83h66.66c16.67 0 29.17-12.5 29.17-29.16v-4.17c-16.67-91.67-91.67-162.5-187.5-170.83v-100c0-16.67-12.5-29.17-33.33-33.34h-62.5c-16.67 0-29.17 12.5-33.34 33.34v95.83c-125 16.67-204.16 100-204.16 204.17 0 137.5 83.33 191.66 258.33 212.5 116.67 20.83 154.17 45.83 154.17 112.5s-58.34 112.5-137.5 112.5c-108.34 0-145.84-45.84-158.34-108.34-4.16-16.66-16.66-25-29.16-25h-70.84c-16.66 0-29.16 12.5-29.16 29.17v4.17c16.66 104.16 83.33 179.16 220.83 200v100c0 16.66 12.5 29.16 33.33 33.33h62.5c16.67 0 29.17-12.5 33.34-33.33v-100c125-20.84 208.33-108.34 208.33-220.84z" fill="#fff"/>
        <path d="M787.5 1595.83c-325-116.66-491.67-479.16-370.83-800 62.5-175 200-308.33 370.83-370.83 16.67-8.33 25-20.83 25-41.67V325c0-16.67-8.33-29.17-25-33.33-4.17 0-12.5 0-16.67 4.16-395.83 125-612.5 545.84-487.5 941.67 75 233.33 254.17 412.5 487.5 487.5 16.67 8.33 33.34 0 37.5-16.67 4.17-4.16 4.17-8.33 4.17-16.66v-58.34c0-12.5-12.5-29.16-25-37.5zM1229.17 295.83c-16.67-8.33-33.34 0-37.5 16.67-4.17 4.17-4.17 8.33-4.17 16.67v58.33c0 16.67 12.5 33.33 25 41.67 325 116.66 491.67 479.16 370.83 800-62.5 175-200 308.33-370.83 370.83-16.67 8.33-25 20.83-25 41.67V1700c0 16.67 8.33 29.17 25 33.33 4.17 0 12.5 0 16.67-4.16 395.83-125 612.5-545.84 487.5-941.67-75-237.5-258.34-416.67-487.5-491.67z" fill="#fff"/>
      </svg>
      
      )
      
      : selectedToken.abbreviation === 'USDT' ? 

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="24" height="24"><path d="M1000 0c552.26 0 1000 447.74 1000 1000s-447.76 1000-1000 1000S0 1552.38 0 1000 447.68 0 1000 0" fill="#53ae94"/><path d="M1123.42 866.76V718h340.18V491.34H537.28V718H877.5v148.64C601 879.34 393.1 934.1 393.1 999.7s208 120.36 484.4 133.14v476.5h246V1132.8c276-12.74 483.48-67.46 483.48-133s-207.48-120.26-483.48-133m0 225.64v-.12c-6.94.44-42.6 2.58-122 2.58-63.48 0-108.14-1.8-123.88-2.62v.2C633.34 1081.66 451 1039.12 451 988.22S633.36 894.84 877.62 884v166.1c16 1.1 61.76 3.8 124.92 3.8 75.86 0 114-3.16 121-3.8V884c243.8 10.86 425.72 53.44 425.72 104.16s-182 93.32-425.72 104.18" fill="#fff"/></svg>

      : <div><img style={{width:'24px', height:'24px'}} src='https://brla.digital/favicon.png'/></div>}
    </>
  </div>

  
</div>
                    </button>
                  </div>
                  {(selectedTokenRecebe.abbreviation !== selectedToken.abbreviation ) && !(selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USD' ) && !(selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL' ) && !(selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USDT' ) && !(selectedTokenRecebe.abbreviation==='USD' && selectedToken.abbreviation==='USDT' ) && !(selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD' )  ? (
                                  <ul className="tw-calculator-breakdown " style={{marginBottom:'-20px'}}>
                                    < li style={{width:'350px', marginTop:'-15px'}}>
                                      <span className="tw-calculator-breakdown__icon">
                                        <span>
                                          <span>–</span>
                                        </span>
                                      </span>
                                      <div  className="tw-calculator-breakdown__content">
                                        <span style={{ fontSize:"14px", color:'black'}}  className=" tw-calculator-breakdown-item__value">
                                        {
  isNaN(SocketMessage?.data?.baseFee) ? (
    "Carregando..."
  ) : (
    
    parseFloat(SocketMessage?.data?.baseFee).toFixed(2) +   (selectedTokenRecebe.abbreviation ==='USDC' ? ' USD' : " BRL")
  )
}
                                        </span>
                                        <span className="tw-calculator-breakdown-item__right tw-calculator-breakdown-item__label">
                                          <span data-tracking-id="calculator-payment-select">
                                            <div className="np-select btn-group">
                                              <button
                                                className="btn btn-sm np-btn np-btn-sm  np-text-body-large limpar-botao"
                                                id="np-select-443252"
                                                role="combobox"
                                                aria-controls="np-select-443252-listbox"
                                                aria-expanded="false"
                                                aria-autocomplete="none"
                                                type="button"
                                                
                                              >
                                                <span style={{ fontSize:"14px", border:'none', color:'black'}} className="np-text-body-large text-ellipsis">
                                                  Tarifa total
                                                  custo
                                                </span>
                                                <span
                                                  className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                                  aria-hidden="true"
                                                  role="presentation"
                                                  data-testid="chevron-up-icon"
                                                >
                                                                                             </span>
                                              </button>
                                            </div>
                                          </span>
                                        </span>
                                      </div>
                                    </li>
                                
             
                                    < li style={{width:'350px', marginTop:'-10px'}}>
                                      <span className="tw-calculator-breakdown__icon">
                                        <span>
                                          <span>÷</span>
                                        </span>
                                      </span>
                                      <div  className="tw-calculator-breakdown__content">
                                        <span  className="tw-calculator-breakdown-item__left tw-calculator-breakdown-item__value">
                                          <a
                                            role="button"
                                            className="btn-unstyled limpar-botao"
                                            tabIndex={0}
                                            style={{border:'none'}}
                                          >
                                            <span style={{ fontSize:"14px", color:'black', textDecoration:'none'}} className="tw-calculator-breakdown-rate__value limpar-borda">
                                            {
                                              
  isNaN(SocketMessage?.data?.basePrice) ? (
    "Carregando..."
  ) : (
    parseFloat(SocketMessage?.data?.basePrice).toFixed(4)
  )
}
                                            </span>
                                          </a>
                                        </span>
                                        <span className="tw-calculator-breakdown-item__right tw-calculator-breakdown-item__label">
                                          <span className="np-popover">
                                            <span className="d-inline-block">
                                              <button
                                                className="btn btn-md np-btn np-btn-md   btn-unstyled limpar-botao "
                                                type="button"
                                                style={{
                                                  height: "auto",
                                                  verticalAlign: "bottom"
                                                }}
                                              >
                                                <span style={{ fontSize:"14px", color:'black'}} data-tracking-id="calculator-fixed-rate-tooltip">
                                                  Taxa de câmbio comercial
                                                </span>{" "}
                                                <span
                                                  style={{ whiteSpace: "nowrap" }}
                                                >
                                                 
                                                </span>
                                              </button>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </li>
                                  </ul> ) :(<div></div>)}
                </div>
                <div className={classes.divH2dD16ce6e1}>
                  <div className={classes.labelBeneficiarioRecebe}>Você recebe</div>
               
                  {showListaCoinsRecebe && <ListaCoinsRecebe ref={ListaCoinsRecebeRef} onTokenSelectSwap={handleTokenRecebeSelect} left='0px' top='200px' position='absolute' />}
                  
                  <div className={classes.divTwMoneyInput2}>
                  
                    <div className={classes.pseudo2}>
                   
                      <input  placeholder={
                                                  (selectedToken.abbreviation === 'USD' && (selectedTokenRecebe.abbreviation === 'USDC' || selectedTokenRecebe.abbreviation === 'USDT') )
                                                    ? '1000.00'
                                                    : ((selectedTokenRecebe.abbreviation === 'USDT' || selectedTokenRecebe.abbreviation === 'USDC')  && selectedToken.abbreviation === 'BRL')
                                                        ? (isNaN(SocketMessage?.data?.amountUsd) ? "Carregando..." : (SocketMessage?.data?.amountUsd / 100).toFixed(2))
                                                        : '999.25'
                                                }
                                                onChange={handleRecebeInputChange}
                                                value={isNaN(recebeValue) ? "Carregando..." : recebeValue}
        
                                                className={`${classes._99925} limpar-input`}/>
                    </div>
                    <button
                    

                    type='button' className={`${classes.divDFlex2} limpar-botao`}>
                    <div  className={classes.iCurrencyFlag2}>
  <div ref={buttonRefListaCoinsRecebet}
                    onClick={() => {
                        setMintValue('');
                        setRecebeValue('');
                          setShowListaCoinsRecebe(!showListaCoinsRecebe);
          
                        }} className={classes.brlSvg2}>
    <>
      {selectedTokenRecebe.abbreviation === 'BRL' ? (
        <svg preserveAspectRatio='none' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g clipPath='url(#clip0_62_81)'>
            <mask
              id='mask0_62_81'
              style={{
                maskType: 'luminance',
              }}
              maskUnits='userSpaceOnUse'
              x={0}
              y={0}
              width={24}
              height={24}
            >
              <path d='M24 0H0V24H24V0Z' fill='white' />
            </mask>
            <g mask='url(#mask0_62_81)'>
              <path
                d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37259 18.6274 0 12 0C5.37259 0 0 5.37259 0 12C0 18.6274 5.37259 24 12 24Z'
                fill='#005B13'
              />
              <path d='M1.5 12.0001L12 5.00006L22.5 12.0001L12 19.0001L1.5 12.0001Z' fill='#FFD400' />
              <path
                d='M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51473 14.4853 7.5 12 7.5C9.51473 7.5 7.5 9.51473 7.5 12C7.5 14.4853 9.51473 16.5 12 16.5Z'
                fill='#231D9A'
              />
              <path
                d='M9.49957 10.4999C12.1496 10.4999 14.5496 11.5749 16.2996 13.2749C16.3496 13.0999 16.3996 12.8999 16.4246 12.6999C14.5996 11.0249 12.1746 9.99994 9.49957 9.99994C8.9496 9.99994 8.4246 10.0499 7.92458 10.1249C7.84958 10.2999 7.77457 10.4749 7.69957 10.6749C8.2746 10.5499 8.8746 10.4999 9.49957 10.4999Z'
                fill='white'
              />
            </g>
          </g>
          <defs>
            <clipPath id='clip0_62_81'>
              <rect width={24} height={24} fill='white' />
            </clipPath>
          </defs>
        </svg>
      ) : selectedTokenRecebe.abbreviation === 'USDT' ? (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2000 2000"
        width={24}
        height={24}
      >
        <path
          d="M1000 0c552.26 0 1000 447.74 1000 1000s-447.76 1000-1000 1000S0 1552.38 0 1000 447.68 0 1000 0"
          fill="#53ae94"
        />
        <path
          d="M1123.42 866.76V718h340.18V491.34H537.28V718H877.5v148.64C601 879.34 393.1 934.1 393.1 999.7s208 120.36 484.4 133.14v476.5h246V1132.8c276-12.74 483.48-67.46 483.48-133s-207.48-120.26-483.48-133m0 225.64v-.12c-6.94.44-42.6 2.58-122 2.58-63.48 0-108.14-1.8-123.88-2.62v.2C633.34 1081.66 451 1039.12 451 988.22S633.36 894.84 877.62 884v166.1c16 1.1 61.76 3.8 124.92 3.8 75.86 0 114-3.16 121-3.8V884c243.8 10.86 425.72 53.44 425.72 104.16s-182 93.32-425.72 104.18"
          fill="#fff"
        />
      </svg>
      
      ) : selectedTokenRecebe.abbreviation === 'USDC' ? (
        <svg data-name="86977684-12db-4850-8f30-233a7c267d11" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000">
        <path d="M1000 2000c554.17 0 1000-445.83 1000-1000S1554.17 0 1000 0 0 445.83 0 1000s445.83 1000 1000 1000z" fill="#2775ca"/>
        <path d="M1275 1158.33c0-145.83-87.5-195.83-262.5-216.66-125-16.67-150-50-150-108.34s41.67-95.83 125-95.83c75 0 116.67 25 137.5 87.5 4.17 12.5 16.67 20.83 29.17 20.83h66.66c16.67 0 29.17-12.5 29.17-29.16v-4.17c-16.67-91.67-91.67-162.5-187.5-170.83v-100c0-16.67-12.5-29.17-33.33-33.34h-62.5c-16.67 0-29.17 12.5-33.34 33.34v95.83c-125 16.67-204.16 100-204.16 204.17 0 137.5 83.33 191.66 258.33 212.5 116.67 20.83 154.17 45.83 154.17 112.5s-58.34 112.5-137.5 112.5c-108.34 0-145.84-45.84-158.34-108.34-4.16-16.66-16.66-25-29.16-25h-70.84c-16.66 0-29.16 12.5-29.16 29.17v4.17c16.66 104.16 83.33 179.16 220.83 200v100c0 16.66 12.5 29.16 33.33 33.33h62.5c16.67 0 29.17-12.5 33.34-33.33v-100c125-20.84 208.33-108.34 208.33-220.84z" fill="#fff"/>
        <path d="M787.5 1595.83c-325-116.66-491.67-479.16-370.83-800 62.5-175 200-308.33 370.83-370.83 16.67-8.33 25-20.83 25-41.67V325c0-16.67-8.33-29.17-25-33.33-4.17 0-12.5 0-16.67 4.16-395.83 125-612.5 545.84-487.5 941.67 75 233.33 254.17 412.5 487.5 487.5 16.67 8.33 33.34 0 37.5-16.67 4.17-4.16 4.17-8.33 4.17-16.66v-58.34c0-12.5-12.5-29.16-25-37.5zM1229.17 295.83c-16.67-8.33-33.34 0-37.5 16.67-4.17 4.17-4.17 8.33-4.17 16.67v58.33c0 16.67 12.5 33.33 25 41.67 325 116.66 491.67 479.16 370.83 800-62.5 175-200 308.33-370.83 370.83-16.67 8.33-25 20.83-25 41.67V1700c0 16.67 8.33 29.17 25 33.33 4.17 0 12.5 0 16.67-4.16 395.83-125 612.5-545.84 487.5-941.67-75-237.5-258.34-416.67-487.5-491.67z" fill="#fff"/>
      </svg>
      
      
      )
      : selectedTokenRecebe.abbreviation === 'USD' ? (
        <svg data-name="86977684-12db-4850-8f30-233a7c267d11" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000">
        <path d="M1000 2000c554.17 0 1000-445.83 1000-1000S1554.17 0 1000 0 0 445.83 0 1000s445.83 1000 1000 1000z" fill="#2775ca"/>
        <path d="M1275 1158.33c0-145.83-87.5-195.83-262.5-216.66-125-16.67-150-50-150-108.34s41.67-95.83 125-95.83c75 0 116.67 25 137.5 87.5 4.17 12.5 16.67 20.83 29.17 20.83h66.66c16.67 0 29.17-12.5 29.17-29.16v-4.17c-16.67-91.67-91.67-162.5-187.5-170.83v-100c0-16.67-12.5-29.17-33.33-33.34h-62.5c-16.67 0-29.17 12.5-33.34 33.34v95.83c-125 16.67-204.16 100-204.16 204.17 0 137.5 83.33 191.66 258.33 212.5 116.67 20.83 154.17 45.83 154.17 112.5s-58.34 112.5-137.5 112.5c-108.34 0-145.84-45.84-158.34-108.34-4.16-16.66-16.66-25-29.16-25h-70.84c-16.66 0-29.16 12.5-29.16 29.17v4.17c16.66 104.16 83.33 179.16 220.83 200v100c0 16.66 12.5 29.16 33.33 33.33h62.5c16.67 0 29.17-12.5 33.34-33.33v-100c125-20.84 208.33-108.34 208.33-220.84z" fill="#fff"/>
        <path d="M787.5 1595.83c-325-116.66-491.67-479.16-370.83-800 62.5-175 200-308.33 370.83-370.83 16.67-8.33 25-20.83 25-41.67V325c0-16.67-8.33-29.17-25-33.33-4.17 0-12.5 0-16.67 4.16-395.83 125-612.5 545.84-487.5 941.67 75 233.33 254.17 412.5 487.5 487.5 16.67 8.33 33.34 0 37.5-16.67 4.17-4.16 4.17-8.33 4.17-16.66v-58.34c0-12.5-12.5-29.16-25-37.5zM1229.17 295.83c-16.67-8.33-33.34 0-37.5 16.67-4.17 4.17-4.17 8.33-4.17 16.67v58.33c0 16.67 12.5 33.33 25 41.67 325 116.66 491.67 479.16 370.83 800-62.5 175-200 308.33-370.83 370.83-16.67 8.33-25 20.83-25 41.67V1700c0 16.67 8.33 29.17 25 33.33 4.17 0 12.5 0 16.67-4.16 395.83-125 612.5-545.84 487.5-941.67-75-237.5-258.34-416.67-487.5-491.67z" fill="#fff"/>
      </svg>
      
      
      
      )

      :
      
      <div><img style={{width:'24px', height:'24px'}} src='https://brla.digital/favicon.png'/></div>}
    </>
  </div>
</div>


                    </button>
                  </div>
                </div>

               

{(selectedTokenRecebe.abbreviation !== selectedToken.abbreviation ) && !(selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD' ) && !(selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL' ) && !(selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USD' ) && !(selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USDT' ) && !(selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USDT' ) && !(selectedTokenRecebe.abbreviation==='USD' && selectedToken.abbreviation==='USDT' )  ? (


<div style={{
  fontSize: "14px", 
  marginTop: selectedTokenRecebe.abbreviation === 'USDT' ? '-10px' : '-10px',
  marginBottom:'20px',
}} className="tw-calculator-effective-rate">
  <strong style={{color:'black'}}>1 USD = {isNaN(SocketMessage?.data?.amountBrl) || isNaN(SocketMessage?.data?.amountUsd)
? "Carregando... "
: (SocketMessage?.data?.amountBrl / SocketMessage?.data?.amountUsd).toFixed(4)
+" BRL"}
</strong>{" "}
  <span className="np-popover">
    <span className="d-inline-block">
      <button
        className="limpar-botao"
        type="button"
        style={{
          height: "auto",
          verticalAlign: "bottom",
          fontSize:'14px',
          color:'black',
          marginLeft:'25px',
          marginTop:"4px"
        }}
      >
        Câmbio efetivo (VET)
      </button>
    </span>
  </span>
</div> ) :(<div></div>)}


{users?.twoFAActivated &&
                            <>

<div style={{marginTop:'-40px'}} className={classes.divH2dBd04bbe1}>
      <div className={classes.labelChavePIX}>2FA</div>

    
      <div className="verify-input-container"  >
                    <div className="code-input-container" style={{ display: 'flex', gap: '20px' }}>
                    <div className={`code-section${codeoneActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_2');
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                          }}
                          onFocus={() => {
                            SetcodeoneActive(true);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_1', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codetwoActive ? ' active' : ''}`}>

                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_3');
                            SetcodetreeActive(true);
                            SetcodetwoActive(false);
                          }}
                          onFocus={() => {
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_2', { required: true })}
                        />

                      </div>
                      <div className={`code-section${codetreeActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_4');
                            SetcodefourActive(true);
                            SetcodetreeActive(false);
                          }}
                          onFocus={() => {
                            SetcodetreeActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_3', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefourActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_5');
                            SetcodefiveActive(true);
                            SetcodefourActive(false);
                          }}
                          onFocus={() => {
                            SetcodefourActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_4', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefiveActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_6');
                            SetcodesixActive(true);
                            SetcodefiveActive(false);
                          }}
                          onFocus={() => {
                            SetcodefiveActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_5', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codesixActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onFocus={() => {
                            SetcodesixActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                          }}
                          {...register('code_6', { required: true })}
                        />
                      </div>
                    </div>
                  </div>
    
    </div>
                             </>}
        
                <div className={classes.alert}>
                  <div className={classes.spanStatusCircle}>
                    <div className={classes.sVG}>
                    <svg preserveAspectRatio='none' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M14.8573 13.1427H17.1427V26.2853H14.8573V13.1427Z' fill='white' />
            <path
              d='M16 10.2853C17.2622 10.2853 18.2853 9.26215 18.2853 8C18.2853 6.73784 17.2622 5.71467 16 5.71467C14.7378 5.71467 13.7147 6.73784 13.7147 8C13.7147 9.26215 14.7378 10.2853 16 10.2853Z'
              fill='white'
            />
          </svg>
                    </div>
                  </div>


                  <div  className={classes.divAlert__message}>
                    <div style={{display:"inline"}}  className={classes.aoContinuarVoceAceitaOsTermosD}>
                      Ao continuar, você aceita os Termos de Uso da nossa empresa.
                      <span style={{display:"inline"}}><a href='https://brla.digital/terms-and-services-pt.pdf' target='_blank'>Saiba mais</a>
                  
                      </span>
                
                    </div>
                    
                  </div>
                </div>
                {isInvalid &&
                <div className={classes.oValorDeveSerMaiorQueR75EMenor}>
                  O valor deve ser maior que R$0.75 e menor ou igual saldo disponível.
                </div>}
        
                {message && message.text &&
                <div className={classes.oValorDeveSerMaiorQueR75EMenor}>
                  {message.text}
                </div>}
        
        
                <button disabled={((mintValue==='' || recebeValue==='' || mintValue===0 || recebeValue===0) && (mintValue==='' || recebeValue==='' || mintValue===0 || recebeValue===0)) || isInvalid || isLoading  || (isNaN(SocketMessage?.data?.baseFee) && ((selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='BRL') || (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USD')) ) ||    (!isValid && users?.twoFAActivated   )  } className={classes.button}>
                  <div className={classes.confirmarTransferencia}> {isLoading ? "Carregando..." : "Confirmar troca"}</div>
                </button>
              </div>
            
            </div>
        
            </form>) :   <div style={{display:"flex", justifyContent:"center", paddingTop:"80px", paddingBottom:"100px"}} className="row mx-auto">
                                                
                               
                                 
            <img style={{width:'200px', height:'200px'}} src={Checked} />
            
            <p style={{marginTop:"20px", marginBottom:'10px', width:'400px'}}>Sua transação será processada, por favor aguarde e acompanhe o status em "Transações".</p>
          </div> }
          </>
)};
    export default SwapMobile;