import React, { useState, useEffect, useRef } from 'react';
import logoImage from '../Home/images/logo.png';
import { useForm } from 'react-hook-form';
import logoImage2 from '../Home/images/logo3.png';
import zxcvbn from 'zxcvbn';
import { useNavigate, useLocation } from 'react-router-dom'

import { ENDPOINT, MANTEINENCETOKEN} from '../../variaveis';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import InputMask from 'react-input-mask';
import debounce from 'lodash.debounce';
import './index.f7a02dd1.css';
import './index.8f9f84f8.css';
import './index.2b6c56de.media_common.css';
import './index.2b6c56de.media_cover.css';




function EmailVerified() {
  const [codeoneActive, SetcodeoneActive] = useState(false);
  const [codetwoActive, SetcodetwoActive] = useState(false);
  const [codetreeActive, SetcodetreeActive] = useState(false);
  const [codefourActive, SetcodefourActive] = useState(false);
  const [codefiveActive, SetcodefiveActive] = useState(false);
  const [codesixActive, SetcodesixActive] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    setValue,
    setFocus,
    watch, // Adicione esta linha
  } = useForm({ mode: 'onChange' });
  
  const [isLoading, setIsLoading] = useState(false);

  const [userData, setuserData] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Recupera os dados do localStorage
    const userDataString = localStorage.getItem('user_data');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      // Define os estados do componente com os dados recuperados
     setuserData(userData);
     console.log("userData: ", userData)
    }
  }, []); // A dependência vazia garante que este efeito é executado apenas uma vez no carregamento da página


 const RetryEmail = async () => {
        
        
        const response = await fetch(`${ENDPOINT}/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "MAINTENANCE-TOKEN":MANTEINENCETOKEN
          },
          body: JSON.stringify(userData),
          
        });
      
        
        if (response.status === 200 || response.status === 201) {
          toast.success('Verifique as instruções enviada por e-mail');
          setIsButtonDisabled(true); // Desabilita o botão para evitar cliques repetidos
          setSecondsLeft(60); // Reinicia o cronômetro para 60 segundos
      
          // Coloque aqui a lógica para enviar a requisição novamente
          // ...
      
          // Simulei um tempo de resposta com setTimeout apenas para demonstração
          setTimeout(() => {
            setIsButtonDisabled(false); // Habilita o botão novamente após o tempo de resposta simulado
          }, 2000); // Substitua o valor 2000 pelo tempo de resposta real da sua requisição
        } else {
          
          const  responseData = await response.json();
          
          // Trate erros aqui, caso necessário
        
          toast.error('Error: ' + responseData.error); // Concatene a mensagem de erro
      
         
        }
      };

      const [secondsLeft, setSecondsLeft] = useState(60);
      const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    
      useEffect(() => {
        // Função que irá atualizar o cronômetro a cada segundo
        const interval = setInterval(() => {
          setSecondsLeft((prevSeconds) => prevSeconds - 1);
        }, 1000);
    
        // Define a função que será executada quando o componente for desmontado
        return () => {
          clearInterval(interval);
        };
      }, []);
    
      // Função para reiniciar o cronômetro quando o botão for clicado


      const onSubmit = async (data) => {
        setIsLoading(true); // Definir como loading quando a requisição começar
        const requestData = {
          email: userData.email,
          token: data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6,
          
        };
      
        // Salvar o corpo da requisição no localStorage
        localStorage.setItem('user_data', JSON.stringify(requestData));
        const response = await fetch(`${ENDPOINT}/validate`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            "MAINTENANCE-TOKEN":MANTEINENCETOKEN
          },
          body: JSON.stringify(requestData),
          
        });
      
        setIsLoading(false); // Definir como não loading quando a requisição terminar
      
        if (response.status === 200 || response.status === 201) {
          toast.success('Conta criada com sucesso');
          setTimeout(() => {
            // Salva os dados no localStorage antes de redirecionar para a próxima página
            navigate('/login');
          }, 3000);
        } else {
          
          const  responseData = await response.json();
          
          // Trate erros aqui, caso necessário
        
          toast.error('Error: ' + responseData.error); // Concatene a mensagem de erro 
         
        }
      };


    return (
       
<>
  <div className="home-container home-register-container">
    <div id="root">
      <div className="register-main-container">
        <div id="registerWarp" className="passport-bg-okex">
          <div className="register-container-v2 verify-container">
            <div className="okx-register-section">
              <div className="okex-register-part-main register-part-main ">
                <div className="verify-code-module">
                  <div>
                    <div className="index_title__TWFEF">Digite o código</div>
                    <div className="index_description__fWv6I">
                      <p>Enviamos o código para {userData?.email}</p>
                    </div>
                  </div>
                  



<form onSubmit={handleSubmit(onSubmit)}>
                  <div className="index_gutter__a2hta" />
                  <div className="verify-input-container">
                    <div className="code-input-container">
                    <div className={`code-section${codeoneActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_2');
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                          }}
                          onFocus={() => {
                            SetcodeoneActive(true);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_1', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codetwoActive ? ' active' : ''}`}>

                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_3');
                            SetcodetreeActive(true);
                            SetcodetwoActive(false);
                          }}
                          onFocus={() => {
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_2', { required: true })}
                        />

                      </div>
                      <div className={`code-section${codetreeActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_4');
                            SetcodefourActive(true);
                            SetcodetreeActive(false);
                          }}
                          onFocus={() => {
                            SetcodetreeActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_3', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefourActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_5');
                            SetcodefiveActive(true);
                            SetcodefourActive(false);
                          }}
                          onFocus={() => {
                            SetcodefourActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_4', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefiveActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_6');
                            SetcodesixActive(true);
                            SetcodefiveActive(false);
                          }}
                          onFocus={() => {
                            SetcodefiveActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_5', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codesixActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onFocus={() => {
                            SetcodesixActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                          }}
                          {...register('code_6', { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                

                  <div className="index_gutter__a2hta" />
                  <button
                    disabled={isLoading || !isValid}
                    type="submit"
                    className="okui-btn btn-lg btn-fill-highlight btn-disabled"
                    style={{ width: "100%", minWidth: "100%", background:"#08383f", color:'white' }}
                  >
                    <span>{isLoading ? "Carregando..." : "Confirmar"}</span>
                  </button>
                  </form>
                  <div className="index_tip-box__S6FZd">
                  <div className="verify-operate new-verify-code">
      <span className="operate-tips">Não recebeu nada? </span>
      {secondsLeft <= 0 ? (
        <button className="button-reenviar" onClick={RetryEmail}>
          Reenviar código
        </button>
      ) : (
        <span className="operate-item send-timer"> Reenviar código ({secondsLeft})</span>
      )}
    </div>
                  </div>
                  <div className="index_gutter__a2hta" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="hometpl"></div>
  <ToastContainer />
</>
              
      
    );
}

export default EmailVerified;
