import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector) // Use language detector
  .use(initReactI18next)
  .init({
    fallbackLng: "ptbr", // Fallback language
    interpolation: {
      escapeValue: false,
    },
    detection: { // Options for language detector
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage']
    },
    resources: {
      ptbr: {
        translation: {
          welcome: "Bem-vindo",
          transfer: "Transferência",
          deposit: "Depósito",
          convert: "Converter",
          total_value: "Valor total da conta",
          real: "Real brasileiro",
          dolar: "USDC Bridged",
          recent_activity: "Atividade recente",
          view_all: "Ver todas",
          identification: "Identificação",
          date: "Data",
          value: "Valor",
          description: "Descrição",
          export_all: "Exportar tudo",
          failed_transaction: "A transação falhou. Tente novamente",
          received_from_wallet: ' recebido da wallet ',
          transferred_to_wallet: ' transferido para a wallet ',
          convert_brl_to_usd: "Troca de BRL para USD",
          convert_usd_to_brl: "Troca de USD para BRL",
          value_paid_to_cpf: 'Valor pago para o CPF ',
          value_paid_to_cnpj: 'Valor pago para o CNPJ ',
          pix_received_from_cpf: 'PIX recebido do CPF ',
          pix_received_from_cnpj: 'PIX recebido do CNPJ ',
          all_rights_reserved: 'Todos os direitos reservados.',
          terms_of_use: 'Termos de uso',
          compliance_policy: 'Política de compliance',
          transactions: 'Transações',
          settings: 'Configurações',
          activity_history: 'Histórico de atividade',
          no_transactions: 'Sem transações',
          profile: 'Perfil',
          limits: 'Limites',
          name: 'Nome',
          password: 'Senha',
          verification: 'Verificação',
          main_asset: 'Ativo principal',
          change_password: 'Alterar senha',
          enable_2fa: 'Ativar o 2FA',
          increase_limits: 'Aumente seu limite',
          mesma_titularidade: 'Saque PIX - mesma titularidade',
          limite_mensal_de_saque: 'Limite mensal de saque',
          saque_terceiros: 'Saque PIX - titularidade terceira',
          limite_convert: 'Limite mensal de conversão',
          voce_envia_exatamente: 'Você envia exatamente',
          beneficiario_recebe: 'Beneficiário recebe',
          voce_recebe: 'Você recebe',
          chave_pix: 'Chave PIX',
          digite_sua_chave_pix: 'Digite sua chave PIX',
          carregando: 'Carregando',
          confirmar_troca:'Confirmar troca',
          tarifa_total_de_custo: 'Tarifa total de custo',
          taxa_de_cambio_comercial: 'Taxa de câmbio comercial',
          cambio_efetivo: 'Câmbio efetivo',
          termos: 'Ao continuar, você aceita os Termos de Uso da nossa empresa.',
          saiba_mais: 'Saiba mais',
          cpf_do_beneficiario: "CPF/CNPJ do beneficiário",
          dados_do_beneficiario: "Dados do beneficiário",
          titular: "Nome do titular da conta",
          agencia: "Agência bancária",
          numero_da_conta: "Número da conta",
          banco: "Banco",
          wallet_address: "Endereço da wallet",
          complete: "Sua transação será processada, por favor aguarde e acompanhe o status em 'Transações'.",
          confirm_transfer: "Confirmar transferência",
          valor_maior: "O valor deve ser maior que R$ ",
          saldo_disponivel: "e menor ou igual saldo disponível.",
          valor_menor:"O valor deve ser menor que 50.000",
          menu_about: "Sobre",
          menu_api: "API Docs",
          menu_contato: "Contato",
          menu_whitepaper: "Whitepaper",
          menu_sign: "Cadastre-se",
          brazilian_financial: "Infraestrutura Financeira para Empresas de Crypto",
          brazilian_financial_bottom: "Nós desenvolvemos soluções financeiras para ajudar empresas de crypto a captar usuários, criar produtos e tocar o dia a dia de suas operações",
          call_apis: "Veja nossas APIs",
          call_brla: "Compre BRLA",
          call_contact: "Fale Conosco",
          on_off: "Rampa Potencializada por BRLA",
          on_off_bottom: "Ao usar nossas APIs, Empresas podem oferecer rampa entre BRL e BRLA, USDC ou USDT por meio do PIX. A rampa com BRLA não possui custo para o usuário",
          create_products: "Crie Produtos em BRLA",
          create_products_bottom: "Empresas podem usar nossas APIs para integrar uma solução de swap de BRLA versus USDC ou USDT para seus usuários. É possível traduzir todos os produtos oferecidos a partir de Stablecoins em USD para BRLA, a fim de alcançar adequadamente os clientes brasileiros",
          brla_account: "Conta BRLA",
          brla_account_bt1: "Ao possuir uma Conta BRLA, Empresas podem lidar facilmente com suas operações financeiras no Brasil",
          brla_account_bt2: "Pague seus funcionários e contratados",
          brla_account_bt3: "Fature seus clientes e contrapartes",
          brla_account_bt4: "Envie e receba dinheiro do exterior usando nossa solução de Swap",
          individual: "Você é uma pessoa física?",
          individual_bt: "Você também pode criar sua Conta BRLA e ser capaz de realizar a entrada/saída (On/Off-Ramp) entre BRL e BRLA, USDC ou USDT",
          follow_social: "Siga BRLA nas Redes",
          name: "Nome",
          company: "Empresa",
          phone: "Fone",
          address: "Endereço",
          submit: "Enviar",
          disclaimer: `Ativos digitais estão sujeitos a uma série de riscos, incluindo volatilidade de preços.
                      Transacionar com ativos digitais pode resultar em perdas significativas e pode não ser adequado
                      para alguns consumidores. Os mercados e as exchanges de ativos digitais não são regulados com os
                      mesmos controles ou proteções ao cliente disponíveis em outras formas de produtos financeiros
                      e estão sujeitos a um ambiente regulatório em constante evolução. Ativos digitais geralmente
                      não têm status de moeda de curso legal e não são cobertos por seguro de proteção de depósito.
                      O desempenho passado de um ativo digital não é um guia para o desempenho futuro, nem é um 
                      indicador confiável de resultados ou desempenho futuros.`,
          token_bottom: "BRLA é uma stablecoin vinculada ao BRL criada pela BRLA Digital, com o objetivo de fornecer um mecanismo conveniente, econômico e estável para os brasileiros acessarem o mundo das criptomoedas.",
          call_whitepaper: "Veja nosso Whitepaper",
          transparent: "Transparente e Seguro",
          transparent_bt: "Todos os tokens BRLA são estritamente respaldados por seu equivalente em Reais brasileiros. Para cada 1 BRLA emitido, há 1 Real brasileiro em dinheiro ou títulos do governo de curto prazo. Publicamos diariamente um registro de todos os tokens e reservas.",
          transparent_page: "Página de Transparência",
          access_best: "Acesse o Melhor de Crypto",
          access_best_bt: "Com mais de 6 milhões de usuários de criptomoedas no Brasil, é crucial que eles tenham acesso fácil aos melhores produtos e serviços de criptomoedas em todo o mundo.",
          create_brla: "Crie sua Conta BRLA e comece a usar nossas soluções de Entrada/saída (On/Off-Ramp) e Swap diretamente através do PIX.",
          vision: "Nossa visão é ser a maior infraestrutura para desenvolver o ecossistema crypto brasileiro",
          mission: "Nossa missão é trazer prosperidade por meio do melhor de crypto",
          mission_bt: "A BRLA Digital é uma empresa de tecnologia que promove o crescimento econômico e a inclusão financeira por meio de tecnologias e aplicativos inovadores em crypto",
          team: "Equipe",
          matheusmoura: `Matheus é Co-Fundador CEO da BRLA Digital, cujo objetivo é conectar e desenvolver o ecossistema de criptomoedas no Brasil 
                        por meio de sua infraestrutura de stablecoin real. Anteriormente, ele foi fundador e CIO da Ada Capital, 
                        uma gestora de ativos brasileira focada em criptomoedas. Ele foi sócio da HIX Capital, onde era 
                        responsável pela equipe de investimentos em ações brasileiras, e se formou em Engenharia Mecânica-Aeronáutica 
                        pelo ITA.`,
          luizcastelo: `Luiz é Co-Fundador e CTO da BRLA Digital. Ele foi Head de estratégias quantitativas na Ada Capital. 
                        Antes disso, ele foi sócio da HIX Capital, onde liderou a equipe de tecnologia e estratégias quantitativas. 
                        Ele trabalhou com Ciência de Dados na Constellation Asset Management, outra grande gestora de ações. Luiz foi 
                        fundador e gestor da Camerius, um veículo de investimento quantitativo proprietário. Ele trabalhou no setor de 
                        risco do Banco BTG Pactual. Luiz se formou em Engenharia Mecânica-Aeronáutica pelo Instituto Tecnológico de 
                        Aeronáutica (ITA) e possui o certificado CGA (Certificado de Gestores da Anbima).`,
          hectorfardin: `Hector é Co-Fundador e Diretor de Operações da BRLA Digital. Ele foi Chefe de Operações, Risco e Conformidade na Ada Capital. 
                        Antes disso, ele trabalhou na Safari Capital como sócio, COO e Gerente de Risco. Em sua função atual, Hector é 
                        responsável pelos aspectos operacionais, de risco e conformidade do negócio na BRLA Digital. Hector possui 
                        graduação em Engenharia Aeroespacial pelo Instituto Tecnológico de Aeronáutica (ITA). Ele também concluiu um 
                        programa de especialização em gerenciamento de riscos na B3 Educação e foi aprovado no exame Parte 1 do FRM.`,
          lucasgiorgio: `Lucas é Co-Fundador e Diretor de Desenvolvimento de Negócios da BRLA Digital. Ele foi Chefe de Relações com Investidores 
                        e Desenvolvimento de Negócios na Ada Capital. Ele tem formação em finanças e iniciou sua carreira na divisão 
                        de auditoria da PwC. Lucas posteriormente fez a transição para Vendas e Relações com Investidores na Pátria 
                        Investimentos, e eventualmente se tornou sócio da HIX Capital, onde foi responsável pelas relações com 
                        investidores e captação de recursos para estratégias de investimento líquidas e privadas. Lucas possui 
                        graduação em Administração de Empresas, com especialização em gestão comercial, e atualmente está 
                        cursando um programa de extensão na Harvard University.`,
          ericbastos: `Éric faz parte da Equipe Fundadora e é Líder de Tecnologia na BRLA Digital. Éric foi Engenheiro de Software na 
                      Ada Capital, trabalhando principalmente em estratégias de investimento quantitativo. Antes de ingressar na Ada, 
                      Éric trabalhou no Middle Office da RB Capital. Éric possui graduação em Engenharia da Computação pelo Instituto 
                      Tecnológico de Aeronáutica (ITA).`,
          caioelyanes: `Caio faz parte da Equipe Fundadora e é Gerente de Tecnologia na BRLA Digital. 
                        Ele foi anteriormente analista de criptomoedas na Ada Capital. Antes disso, Caio criou e liderou 
                        a equipe de Business Intelligence na Pagar.me (adquirida pela Stone) e trabalhou na Kraft Heinz. 
                        Caio estudou Administração de Empresas na FGV.`,
                        check_policy:"Confira nossa política de termos e serviços",
          here:'aqui',
          fique_atento:'Fique atento também à nossa política de Compliance, AML e CFT',
          remeinders:"Lembretes:",
          aviso_wallet:"Este endereço de carteira está na rede Polygon, e não temos controle ou responsabilidade sobre um endereço igual em outra rede. Tokens enviados para este endereço em outras redes poderão ser perdidos.",
          tokens_aceitos:"Aceitamos apenas o recebimento de BRLA, USDC ou USDT.",
          perda:"Qualquer outro token enviado poderá ser perdido.",
          login:"Entrar",
          register:"Registrar-se",
          forgot_password:"Esqueceu a senha?",
          wrong_credentials:"Credenciais invalidas",
          create_account:"Crie sua conta BRLA",
          data_de_nasciemento:"Data de nascimento",
          nome_completo:"Nome completo",
          country:"País",
          zip_code:"CEP",
          city:"Cidade",
          state:"Estado",
          neighborhood:"Bairro",
          street:"Logradouro",
          number:"Número",
          complement:"complemento",
          confirm_password:"Confirmar senha",
          password_warning:"A senha deve ter pelo menos 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.",
          send_terms:"Ao enviar este formulário, você concorda com os ",
          terms_link: "Termos e serviços",
          pessoa_fisica:"Pessoa física",
          pessoa_juridica:"Pessoa jurídica",
          ja_tem_uma_conta:"Já tem uma conta?",
          cep_invalido:"CEP inválido",
          Phone:"Celular",
          cpf_representante:"CPF do representante",
          nome_representante:"Nome completo do representante legal",
          data_de_abertura:"Data de abertura",
          razao_social:"Razão social",
          date_of_birth: "Data de nascimento",
          full_name: "Nome completo",
          cpf: 'CPF',
          cnpj: 'CNPJ',
          contact_limit: 'Para solicitar aumento de limite, entrar em contato com compliance@brla.digital',
          onchain_payments:"Depósito On-Chain",
          tipo_de_conta:"Tipo de conta",
          agencia_bancaria:"Agência bancária",
          conta_bancaria:"Conta bancária",
          nome_do_beneficiario: "Nome completo do beneficiário",
          refund: "Reembolso recebido de BRLA",
          charges: "Taxa relativa ao depósito de entrada de"

        },
      },
      en: {
        translation: {
          welcome: "Welcome",
          transfer: "Transfer",
          deposit: "Deposit",
          convert: "Convert",
          total_value: "Total account value",
          real: "Brazilian reais",
          dolar: "USDC Bridged",
          recent_activity: "Recent activity",
          view_all: "View all",
          identification: "Identification",
          export_all: "Exportar tudo",
          failed_transaction: "Transaction failed. Please try again.",
          received_from_wallet: '  received from the wallet ',
          transferred_to_wallet: ' transferred to the wallet ',
          convert_brl_to_usd: "BRL to USD convert",
          convert_usd_to_brl: "USD to BRL convert",
          value_paid_to_cpf: 'Value paid to CPF ',
          value_paid_to_cnpj: 'Value paid to CNPJ ',
          pix_received_from_cpf: 'PIX received from CPF ',
          pix_received_from_cnpj: 'PIX received from CNPJ ',
          date: "Date",
          value: "Value",
          description: "Description",
          export_all: "Export all",
          all_rights_reserved: 'All Rights Reserved.',
          terms_of_use: 'Terms of use',
          compliance_policy: 'Compliance policy',
          transactions: 'Transactions',
          settings: 'Settings',
          activity_history: 'Activity history',
          no_transactions: 'No transactions',
          profile: 'Profile',
          limits: 'Limits',
          name: 'Name',
          password: 'Password',
          verification: 'Verification',
          main_asset: 'Main asset',
          change_password: 'Change password',
          enable_2fa: 'Enable 2FA',
          increase_limits: 'Increase your limits',
          mesma_titularidade: 'PIX Withdrawal - Same ownership',
          limite_mensal_de_saque: 'Monthly withdrawal Limit',
          saque_terceiros: 'PIX Withdrawal - Third-party ownership',
          limite_convert: 'Monthly conversion limit',
          voce_envia_exatamente: 'You send exactly',
          beneficiario_recebe: 'Beneficiary receives',
          voce_recebe: 'You receive',
          chave_pix: 'PIX key',
          digite_sua_chave_pix: 'Enter your PIX key',
          carregando: 'Loading',
          confirmar_troca:'Confirm exchange',
          tarifa_total_de_custo: 'Total cost fee',
          taxa_de_cambio_comercial: 'Commercial exchange rate',
          cambio_efetivo: 'Effective exchange rate',
          termos: 'By continuing, you accept our Terms of Use.',
          saiba_mais: 'Learn more',
          cpf_do_beneficiario: "CPF/CNPJ of the receiver",
          dados_do_beneficiario: "Receiver information",
          titular: "Account holder name",
          agencia: "Bank branch",
          numero_da_conta: "Account number",
          banco: "Bank",
          wallet_address: "Wallet address",
          complete: "Your transaction will be processed, please wait and track the status in 'Transactions'.",
          confirm_transfer: "Confirm transfer",
          valor_maior:"The value must be greater than ",
          saldo_disponivel: " and less than or equal to the available balance.",
          valor_menor:"The value must be less than 50,000.",
          menu_about: "About",
          menu_api: "API Reference",
          menu_contato: "Contact",
          menu_whitepaper: "Whitepaper",
          menu_sign: "Sign Up",
          brazilian_financial: "Brazilian Financial Infrastructure for Crypto Companies",
          brazilian_financial_bottom: "We have developed financial solutions to help Crypto Companies to onboard users, create products and handle its local operations",
          call_apis: "Check our APIs",
          call_brla: "Buy BRLA",
          call_contact: "Contact Us",
          on_off: "On/Off-Ramp Powered by BRLA",
          on_off_bottom: "By using our APIs, Companies can offer On/Off-Ramp between BRL and BRLA, USDC or USDT by PIX. The On/Off-Ramp with BRLA has zero cost for user",
          create_products: "Create Products in BRLA",
          create_products_bottom: "Companies can use our APIs to integrate a BRLA to USDC or USDT Swap Solution for their users. It is possible to translante every product offering from USD Stablecoins to BRLA in order to properly reach Brazilian clients",
          brla_account: "BRLA Account",
          brla_account_bt1: "By having a BRLA Account, Companies can handle their financial operations in Brazil easily",
          brla_account_bt2: "Pay your employees and contractors",
          brla_account_bt3: "Invoice your clients and counterparties",
          brla_account_bt4: "Send and Receive money from abbroad using our Swap Solution",
          individual: "Are you an Individual?",
          individual_bt: "You can also create your BRLA Account and be able to On/Off-Ramp between BRL and BRLA, USDC or USDT",
          follow_social: "Follow BRLA on Social",
          name: "Name",
          company: "Company",
          phone: "Phone",
          address: "Address",
          submit: "Submit",
          disclaimer: `Digital assets are subject to a number of risks, including price
                      volatility. Transacting in digital assets could result in
                      significant losses and may not be suitable for some consumers.
                      Digital asset markets and exchanges are not regulated with the
                      same controls or customer protections available with other forms
                      of financial products and are subject to an evolving regulatory
                      environment. Digital assets do not typically have legal tender
                      status and are not covered by deposit protection insurance. The
                      past performance of a digital asset is not a guide to future
                      performance, nor is it a reliable indicator of future results or
                      performance`,
          token_bottom: "BRLA is a BRL-pegged stablecoin created by BRLA Digital, aimed at providing a convenient, cost-effective, and price-stable mechanism for Brazilians to access the crypto world",
          call_whitepaper: "Check our Whitepaper",
          transparent: "Transparent and Secure",
          transparent_bt: "All the BRLA tokens are strictly backed by their equivalent in Brazilian Reais. For every 1 BRLA minted, there is 1 Brazilian Real in cash or short term government bonds. We post a daily record of all tokens and reserves.",
          transparent_page: "Transparency Page",
          access_best: "Access the Best of Crypto",
          access_best_bt: "With over 6 million crypto users in Brazil, it is crucial for them to have easy access to the best crypto products and services worldwide",
          create_brla: "Create your BRLA Account and start using our On/Off-Ramp and Swap Solutions directly through PIX",
          vision: "Our Vision is to be the largest infrastructure to develop the Brazilian Crypto Ecosystem",
          mission: "Our mission is to bring prosperity through the best of crypto",
          mission_bt: "BRLA Digital is a technology company fostering economic growth and financial inclusion through groundbreaking crypto technologies and applications",
          team: "Team",
          matheusmoura: `Matheus is CEO of BRLA Digital, whose objective is to connect and develop the Crypto ecosystem in 
          Brazil through its real stablecoin infrastructure. Previously, he was founder and CIO of Ada Capital, a Brazilian 
          asset manager focused on Crypto. He was a partner at HIX Capital, where he was responsible for the investment team 
          in Brazilian equities, and he graduated in Mechanical-Aeronautical Engineering from ITA.`,
          luizcastelo: `Luiz is Co-Founder and CTO at BRLA Digital. He was Manager of quantitative strategies at Ada Capital. 
          Before that he was a partner at HIX Capital, where he led the technology and quantitative strategies team. 
          He worked with Data Science at Constellation Asset Management, another large equity manager. Luiz was Founder 
          and manager of Camerius, proprietary quantitative investment vehicle. He worked at Banco BTG Pactual's risk desk. 
          Luiz Graduated in Aeronautical Mechanical Engineering from Instituto Tecnológico de Aeronáutica (ITA) 
          and holds CGA (managers certificate from Anbima)`,
          hectorfardin: `Hector is the Chief Operating Officer at BRLA Digital. He was the Head of Operations, Risk, 
          and Compliance at Ada Capital. Prior to this, he worked at Safari Capital as a partner, COO, and Risk Manager. 
          In his current role, Hector is responsible for the operational, risk, and compliance aspects of the business at 
          BRLA Digital. Hector holds a degree in Aerospace Engineering from the Aeronautics Institute of Technology (ITA). 
          He has also completed a risk management specialization program at B3 Education and has passed the FRM Part 1 exam.`,
          lucasgiorgio: `Lucas is the Chief Business Development Officer at BRLA Digital. He was the Head of Investor Relations 
          and Business Development at Ada Capital. He has a background in finance and began his career in the assurance 
          division at PwC. Lucas later transitioned to Sales and Investor Relations at Pátria Investimentos, and eventually 
          became a partner at HIX Capital where he was responsible for investor relations and fundraising for liquid and 
          private investment strategies. Lucas has a degree in Business Administration, with a specialization in commercial 
          management, and is currently pursuing an Extension School program at Harvard University.`,
          ericbastos: `Éric is part of the Founding Team and a Tech Lead at BRLA Digital. Éric was a Software Engineer at 
          Ada Capital, primarily working on quantitative investment strategies. Prior to joining Ada, Éric worked in the 
          Middle Office at RB Capital. Éric holds a degree in Computer Engineering from the Aeronautics 
          Institute of Technology (ITA).`,
          caioelyanes: `Caio is part of the Founding Team and a Tech Manager at BRLA Digital. 
          He was previously a Crypto analyst at Ada Capital. Before that, Caio created and led the 
          Business Intelligence team at Pagar.me (bought by Stone) and worked at Kraft Heinz. Caio studied Business at FGV`,
          leandronoel: ``,
          check_policy:"Check out our terms and services policy",
          here:'here',
          fique_atento:'Also, please pay attention to our Compliance, AML, and CFT policy.',
          onchain_payments:"On-Chain payments",
          remeinders:"Remeinders",
          aviso_wallet:"This wallet address is on the Polygon network, and we have no control or responsibility over an identical address on another network. Tokens sent to this address on other networks may be lost.",
          tokens_aceitos:"We only accept receipts in BRLA, USDC or USDT.",
          perda:"Any other token sent may be lost.",
          login:"Login",
          register:"Signup",
          forgot_password:"Forgot password?",
          wrong_credentials:"Wrong credentials",
          create_account: "Create your BRLA account",
          date_of_birth: "Date of birth",
          full_name: "Full name",
          country: "Country",
          zip_code: "ZIP code",
          city: "City",
          state: "State",
          neighborhood: "Neighborhood",
          street: "Street",
          number: "Number",
          complement: "Complement",
          confirm_password: "Confirm password",
          password_warning: "Password must be at least 8 characters long, including at least one uppercase letter, one lowercase letter, one number, and one special character.",
          send_terms: "By submitting this form, you agree to the ",
          terms_link: "Terms and services",
          pessoa_fisica:"Individual",
          pessoa_juridica:"Business",
          ja_tem_uma_conta:"Already have an account?",
          cep_invalido:"Invalid ZIP code",
          phone:"Phone",
          cpf_representante:"Tax ID of the legal representative",
          nome_representante:"Full name of the legal representative",
          data_de_abertura:"Date of establishment",
          razao_social:"Legal name",
          cpf: 'Tax ID',
          cnpj: 'Tax ID of the company',
          contact_limit: 'To request limit increase, contact compliance@brla.digital',
          tipo_de_conta:"Account type",
          agencia_bancaria:"Branch code",
          conta_bancaria:"Account number",
          nome_do_beneficiario: "Full name",
          refund: "Refund received from BRLA",
          charges: "Fee relative to Cash-In deposit"

        },
      },
    },
  });

export default i18n;