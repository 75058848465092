import FooterLogged from "../../Components/FooterLogged";
import SettingsHeader from "../../Components/SettingsHeader";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ENDPOINT} from '../../variaveis';
import { useTranslation } from 'react-i18next';
import { color } from "echarts";
function Limits() {
  const navigate = useNavigate();
  const { t, i18n} = useTranslation();
  const [limit, setLimit] = useState(0);
  const [usedLimit, setUsedLimit] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pendingRequests, setPendingRequests] = useState(1);  
  const [users, setUsers] = useState([]);


  useEffect(() => {
    const loadData = async () => {
      const lastRequestDate = localStorage.getItem('lastRequestDate');
      const currentDate = new Date();
  
      if (lastRequestDate && currentDate - new Date(lastRequestDate) < 10000) {
        // If less than a minute has passed since the last request, do not make a new request
        return;
      }
      const resUsedLimit = await fetch(`${ENDPOINT}/used-limit`, {
        method: 'GET',
        credentials: 'include',
      });
      const bodyUsedLimit = await resUsedLimit.json();
      localStorage.setItem('usedLimit', JSON.stringify(bodyUsedLimit));
  
      localStorage.setItem('nextRequestDate', currentDate.toISOString());
      
      // After making the requests, store the current date as the last request date
      localStorage.setItem('lastRequestDate', currentDate.toISOString());
    };
  
    loadData();
  }, []);
  

useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      setUsers(JSON.parse(loadedUsers));
      setLimit(JSON.parse(loadedUsers).kyc.limits);
          }
    const loadedLimit = localStorage.getItem('usedLimit');
    if (loadedLimit) {
      setUsedLimit(JSON.parse(loadedLimit));
      
    }
    
    setPendingRequests(0);
  }, []);


  const usedPercentageOwn = (usedLimit?.limitBRLAOutOwnAccount/ users.kyc?.limits?.limitBRLAOutOwnAccount) * 100;
  const usedPercentageThird = (usedLimit?.limitBRLAOutThirdParty / users.kyc?.limits?.limitBRLAOutThirdParty) * 100;
  const usedPercentageSell = (usedLimit?.limitSwapSell / users.kyc?.limits?.limitSwapSell) * 100;
  const usedPercentageBuy = (usedLimit?.limitSwapBuy / users.kyc?.limits?.limitSwapBuy) * 100;


  useEffect(() => {
    // Se não há requisições pendentes, então as requisições acabaram
    if (pendingRequests === 0) {
      // Adiciona um delay de 0.2 segundos antes de remover o estado de carregamento
      setIsLoading(false);
    } 
  }, [pendingRequests]);

  if (isLoading) {
    // Retorne um spinner de loading, um placeholder ou null
    return <div></div>;
  }

  


    return(
<>
  <div id="__next" style={{overflowX:'hidden'}}>
    <div className="grid w-full min-h-screen font-circular grid min-h-screen w-full">
      <SettingsHeader/>
      <main className="flex flex-col bg-surface-background">
        <div className="grid flex-1 content-start gap-y-12 py-12 main-container">
          <div className="px-16">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
              <header className="cb-header">
                <div className="cb-header-head primary">
                  <h1 className="title">{t('limits')}</h1>
                  <div className="actions"  style={{ display: 'flex', justifyContent: 'flex-end' }} >
               

</div>
                </div>
              <p style={{color:'red', marginTop:"20px"}}>  {t('contact_limit')}</p>
              </header>
        
              <div className="pt-5">
  <div className="grid grid-cols-2 gap-10">



    
  <div
      className="h-72 shadow-md rounded-lg pt-2 px-2 flex flex-col items-center relative font-circular bg-white Withdrawal"
      data-testid="limit-card"
    >
      <span style={{marginTop:'20px', color:'#08383F', fontWeight:900}}>{t('mesma_titularidade')}</span>
      <div className="mb-3 h-10 w-full" />
      <div className="mb-7 flex flex-col items-center">
        <div className="p-2.5  rounded-full mb-3 bg-violet-75 text-violet-300">
          <svg
            width={16}
            height={16}
            viewBox="0 0 14 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className="cb-icon cb-icon-withdrawal pointer-events-none"
            aria-hidden="true"
            data-testid="icon-withdrawal"
            focusable="false"
            role="img"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 15a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1ZM3.293 4.707a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414L8 3.414V11a1 1 0 1 1-2 0V3.414L4.707 4.707a1 1 0 0 1-1.414 0Z"
            />
          </svg>
        </div>
        <span className="mb-2 text-xl leading-5 text-black-600 font-circular-bold">
          {`R$ ${(users?.kyc?.limits?.limitBRLAOutOwnAccount / 100 ?? 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        </span>
        <span className="text-black-200 font-circular-bold" data-testid="title">
        {t('limite_mensal_de_saque')}
        </span>
      </div>
      <div className="cb-progress w-full px-3">
        <span className="max_label" data-testid="max-value">
        {`R$ ${(users?.kyc?.limits?.limitBRLAOutOwnAccount / 100 ?? 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        </span>
        <div className="progress">
          <div
            className="filler primary"
            data-testid="progress"
            style={{ width: `${usedPercentageOwn}%` }}
          />
          
         
        </div>
        <span className="max_label" >R$ {(usedLimit?.limitBRLAOutOwnAccount/ 100).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
         
        <div className="nodes" />
        <div className="descriptions" />
      </div>
    </div>


    <div
      className="h-72 shadow-md rounded-lg pt-2 px-2 flex flex-col items-center relative font-circular bg-white Withdrawal"
      data-testid="limit-card"
    >
      <span style={{marginTop:'20px', color:'#08383F', fontWeight:900}}>{t('saque_terceiros')}</span>
      <div className="mb-3 h-10 w-full" />
      <div className="mb-7 flex flex-col items-center">
        <div className="p-2.5  rounded-full mb-3 bg-violet-75 text-violet-300">
          <svg
            width={16}
            height={16}
            viewBox="0 0 14 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className="cb-icon cb-icon-withdrawal pointer-events-none"
            aria-hidden="true"
            data-testid="icon-withdrawal"
            focusable="false"
            role="img"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 15a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1ZM3.293 4.707a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414L8 3.414V11a1 1 0 1 1-2 0V3.414L4.707 4.707a1 1 0 0 1-1.414 0Z"
            />
          </svg>
        </div>
        <span className="mb-2 text-xl leading-5 text-black-600 font-circular-bold">
          {`R$ ${(users?.kyc?.limits?.limitBRLAOutThirdParty / 100 ?? 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        </span>
        <span className="text-black-200 font-circular-bold" data-testid="title">
        {t('limite_mensal_de_saque')}
        </span>
      </div>
      <div className="cb-progress w-full px-3">
        <span className="max_label" data-testid="max-value">
        {`R$ ${(users?.kyc?.limits?.limitBRLAOutThirdParty / 100 ?? 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        </span>
        <div className="progress">
          <div
            className="filler primary"
            data-testid="progress"
            style={{ width: `${usedPercentageThird}%` }}
          />
          
         
        </div>
        <span className="max_label" >R$ {(usedLimit.limitBRLAOutThirdParty/ 100).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
         
        <div className="nodes" />
        <div className="descriptions" />
      </div>
    </div>



    
    
    <div
      className="h-72 shadow-md rounded-lg pt-2 px-2 flex flex-col items-center relative font-circular bg-white Withdrawal"
      data-testid="limit-card"
    >
      <span style={{marginTop:'20px', color:'#08383F', fontWeight:900}}>BRL to USD</span>
      <div className="mb-3 h-10 w-full" />
      <div className="mb-7 flex flex-col items-center">
        <div className="p-2.5  rounded-full mb-3 bg-violet-75 text-violet-300">
          <svg
            width={16}
            height={16}
            viewBox="0 0 14 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className="cb-icon cb-icon-withdrawal pointer-events-none"
            aria-hidden="true"
            data-testid="icon-withdrawal"
            focusable="false"
            role="img"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 15a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1ZM3.293 4.707a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414L8 3.414V11a1 1 0 1 1-2 0V3.414L4.707 4.707a1 1 0 0 1-1.414 0Z"
            />
          </svg>
        </div>
        <span className="mb-2 text-xl leading-5 text-black-600 font-circular-bold">
          {`R$ ${(users?.kyc?.limits?.limitSwapBuy / 100 ?? 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        </span>
        <span className="text-black-200 font-circular-bold" data-testid="title">
        {t('limite_convert')}
        </span>
      </div>
      <div className="cb-progress w-full px-3">
        <span className="max_label" data-testid="max-value">
        {`R$ ${(users?.kyc?.limits?.limitSwapBuy / 100 ?? 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        </span>
        <div className="progress">
          <div
            className="filler primary"
            data-testid="progress"
            style={{ width: `${usedPercentageBuy}%` }}
          />
          
         
        </div>
        <span className="max_label" >R$ {(usedLimit?.limitSwapBuy/ 100).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
         
        <div className="nodes" />
        <div className="descriptions" />
      </div>
    </div>


        
  <div
      className="h-72 shadow-md rounded-lg pt-2 px-2 flex flex-col items-center relative font-circular bg-white Withdrawal"
      data-testid="limit-card"
    >
      <span style={{marginTop:'20px', color:'#08383F', fontWeight:900}}>USD to BRL</span>
      <div className="mb-3 h-10 w-full" />
      <div className="mb-7 flex flex-col items-center">
        <div className="p-2.5  rounded-full mb-3 bg-violet-75 text-violet-300">
          <svg
            width={16}
            height={16}
            viewBox="0 0 14 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className="cb-icon cb-icon-withdrawal pointer-events-none"
            aria-hidden="true"
            data-testid="icon-withdrawal"
            focusable="false"
            role="img"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 15a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1ZM3.293 4.707a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414L8 3.414V11a1 1 0 1 1-2 0V3.414L4.707 4.707a1 1 0 0 1-1.414 0Z"
            />
          </svg>
        </div>
        <span className="mb-2 text-xl leading-5 text-black-600 font-circular-bold">
          {`R$ ${(users?.kyc?.limits?.limitSwapSell / 100 ?? 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        </span>
        <span className="text-black-200 font-circular-bold" data-testid="title">
        {t('limite_convert')}
        </span>
      </div>
      <div className="cb-progress w-full px-3">
        <span className="max_label" data-testid="max-value">
        {`R$ ${(users?.kyc?.limits?.limitSwapSell / 100 ?? 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
        </span>
        <div className="progress">
          <div
            className="filler primary"
            data-testid="progress"
            style={{ width: `${usedPercentageSell}%` }}
          />
          
         
        </div>
        <span className="max_label" >R$ {(usedLimit?.limitSwapSell/ 100).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
         
        <div className="nodes" />
        <div className="descriptions" />
      </div>
    </div>


  </div>
</div>






             
            </div>
          </div>
        </div>
<FooterLogged/>
      </main>
    </div>
  </div>

</>)};
export default Limits;
