import { memo } from "react"

const CarbonMenuIcon = props => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 57 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 15H37V17H13V15ZM13 33H25H37V35H13V33ZM13 21H37V23H13V21ZM13 27H37V29H13V27Z"
      fill="#00DC84"
    />
  </svg>
)
const Memo = memo(CarbonMenuIcon)
export { Memo as CarbonMenuIcon }
